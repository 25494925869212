<template>
  <div class="purchase_detail" v-loading="loading">
    <div class="Purchase_list_left">
      <div class="purchase_detail_in">
        <div class="Purchase_list_left_top">
          <span>换电线束</span>近期采购
          <div @click="setmyCollect()">
            <img v-if="!collect" src="../../public/NewImage/heart1.png" alt="">
            <img v-else src="../../public/NewImage/heart2.png" alt="">
            收藏
          </div>
        </div>
        <div class="purchase_detail_Message">
          <div class="purchase_detail_phone">
            <div class="purchase_detail_phone_1">
              <img :src="'https://admin.aiauto.shop/' + del.page.touxiang" alt="">
              <p>{{del.page.name}}</p>
            </div>
            <div class="purchase_detail_phone_2">
              <div class="item_list_text"><i></i>姓名: {{del.page.xingming}}</div>
              <div class="item_list_text"><i></i>邮箱: {{del.page.youxiang}}</div>
              <div class="item_list_text"><i></i>手机号: {{del.page.shoujihaoma}}</div>
            </div>
            <div class="purchase_detail_phone_3" v-if="del.page.mid != userId">
              <button @click="SignUpType = true">我要报名</button>
              <span>登陆后可以查看联系人信息</span>
            </div>
          </div>
          <div class="purchase_detail_item">
            <div class="item_list_text"><i></i>适用车型: {{del.page.sycxname}}</div>
            <div class="item_list_text"><i></i>认证要求: {{del.page.rzyqname}}</div>
            <div class="item_list_text"><i></i>采购量: {{del.page.shuliang}}</div>
            <div class="item_list_text"><i></i>发布时间: {{del.page.inputtimename}}</div>
            <div class="item_list_text"><i></i>截至时间: {{del.page.jzsjstr}}</div>
            <div class="item_list_text"><i></i>具体需求:</div>
            <div class="purchase_detail_val">{{del.page.beizhu}}</div>
          </div>
        </div>
        <transition name="el-fade-in-linear">
          <div class="purchase_detail_SignUp" v-show="SignUpType">
            <div class="Purchase_list_left_top">
              <span>我要报名</span>
            </div>
            <div class="purchase_detail_SignUp_from">
              <el-form ref="form" :model="ruleForm" :rules="rules" label-width="90px" size="small">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="公司名称" style="margin-top: 20px;" prop="companyname">
                      <el-input style="width: 100%;" v-model="ruleForm.companyname" placeholder="请输入公司名称" maxlength="100"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="主营业务" style="margin-top: 20px;" prop="zhuying">
                      <el-input style="width: 100%;" v-model="ruleForm.zhuying" placeholder="请输入主营业务" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="配套客户" style="margin-top: 20px;" prop="peitao">
                      <el-input style="width: 100%;" v-model="ruleForm.peitao" placeholder="请输入配套客户" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="地址" style="margin-top: 20px;" prop="dizhi">
                      <el-cascader
                        style="width: 100%;"
                        placeholder="请选择地址"
                        v-model="ruleForm.dizhi"
                        :options="dizhi"
                        :props="defaultParams">
                          <template slot="append">.com</template>
                        </el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="认证证书" style="margin-top: 20px;" prop="zhengshu">
                      <el-input style="width: 100%;" placeholder="请输入认证证书" v-model="ruleForm.zhengshu" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="对买家说" style="margin-top: 20px;" prop="speak">
                      <el-input style="width: 100%;" placeholder="请输入对买家说" v-model="ruleForm.speak" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="上传附件" style="margin-top: 20px;">
                      <el-upload style="width: 100%;"
                        :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                        list-type="picture-card"
                        :multiple="true"
                        :file-list="fileList"
                        :on-success="onSuccess"
                        :on-remove="onRemove">
                        <i class="el-icon-plus"/>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24"> <div class="publishMessage_border"></div> </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人" style="margin-top: 20px;" prop="xingming">
                      <el-input style="width: 100%;" placeholder="请输入联系人" v-model="ruleForm.xingming" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机号码" style="margin-top: 20px;" prop="shouji">
                      <el-input style="width: 100%;" placeholder="请输入手机号码" v-model="ruleForm.shouji" maxlength="11"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="座机" style="margin-top: 20px;">
                      <el-input style="width: 40%;margin-right: 10px;" placeholder="请输入区号" v-model="ruleForm.qu" maxlength="5"></el-input>
                      <el-input style="width: calc(60% - 10px);" placeholder="请输入座机号码" v-model="ruleForm.dianhua" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="邮箱" style="margin-top: 20px;" prop="youxiang">
                      <el-input style="width: 100%;" placeholder="请输入邮箱" v-model="ruleForm.youxiang" maxlength="255"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-button type="primary" class="purchase_detail_SignUp_from_but" @click="addcaigouSignUp()">提交</el-button>
              </el-form>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="Purchase_list_right">
      <div class="Purchase_list_right_in">
        <div class="Purchase_list_right_top">优质项目推荐</div>
        <div class="ProjectRecommendation" v-for="(d,i) in del.highlist" :key="i" @click="listClisk(d)">
          <div class="ProjectRecommendation_tit">{{d.title}}<span>{{d.jjcdname}}</span></div>
          <!-- <p>{{d.sycxname}}</p> -->
          <div class="ProjectRecommendation_text" v-if="d.sycxname"><i></i>适用车型: {{d.sycxname}}</div>
          <div class="ProjectRecommendation_text" v-if="d.rzyqname"><i></i>认证要求: {{d.rzyqname}}</div>
          <div class="ProjectRecommendation_text" v-if="d.shuliang"><i></i>采购量: {{d.shuliang}}</div>
          <span style="display: block;">{{d.inputtimename}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/request.js'
export default {
  data() {
    return {
      toke:window.localStorage.getItem('code'),
      id:'',
      fileList:[],
      SignUpType:false,
      defaultParams: { label: "val", value: "type" },
      dizhi:[],
      collect:1,
      loading:false,
      ruleForm: {
        xxid: '',
        companyname: '',
        zhuying: '',
        peitao: '',
        dizhi: '',
        zhengshu: '',
        speak: '',
        fujian: '',
        xingming: '',
        shouji: '',
        qu: '',
        dianhua: '',
        youxiang: '',
      },
      rules:{
        companyname: [{ required: true, message: '请输入公司名称', trigger:['change','blur'] }],
        zhuying: [{ required: true, message: '请输入主营业务', trigger:['change','blur'] }],
        peitao: [{ required: true, message: '请输入配套客户', trigger:['change','blur'] }],
        dizhi: [{ required: true, message: '请输入地址', trigger:['change','blur'] }],
        zhengshu: [{ required: true, message: '请输入认证证书', trigger:['change','blur'] }],
        speak: [{ required: true, message: '请输入对买家说', trigger:['change','blur'] }],
        xingming: [{ required: true, message: '请输入联系人', trigger:['change','blur'] }],
        shouji: [
          { required: true, message: '请输入手机号', trigger:['change','blur'] },
          { validator: (rule, value, callback) => {
            if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
              if (reg.test(value)) {callback()} else {
                return callback(new Error("请输入正确的手机号"));
              }
            }
          }, trigger: "blur" },],
        youxiang: [{ required: true, message: '请输入邮箱', trigger:['change','blur'] }],
      },
      del:{
        page:{
          touxiang:''
        }
      },
      userId:window.localStorage.getItem("id")
    }
  },
  mounted() {
    this.PurchaseChannelDetile(this.$route.query.id);
    this.id = JSON.parse(window.localStorage.getItem("individual")).id;
  },
  methods:{
    PurchaseChannelDetile(id){
      request.PurchaseChannelDetile(id).then((res) => {
        if(res.data.code == 1){
          this.del = res.data.data;
          console.log(res.data.data)
          if(res.data.data.collect == 1){
            this.collect = true
          }else{
            this.collect = false
          }
          this.dizhi = this.changeNodes(res.data.data.dizhi);
          if(res.data.data.collect == 1){this.collect = true}else{this.collect = false};
        }
      })
    },
    setmyCollect(){
      request.setmyCollect(this.$route.query.id).then((res) => {
        if(res.data.code == 1){
          this.$message({ message: res.data.msg, type: 'success'});
          this.collect =! this.collect
        }else{
          this.$message.error(res.data.msg);
        }
        // setTimeout(() => {
        //   this.loading =! this.loading
        // },1000)
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    onSuccess(response, file, fileList) {
      if(response.code == 0){
        this.$message.error(response.msg);
        this.fileList = []
      }else{
        let val = '';
        this.fileList = fileList;
        fileList.forEach(item => { if(item.response){ val = val + item.response.data.id + ',' } })
        this.ruleForm.fujian = val.substring(0, val.length - 1);
      }
    },
    onRemove(file, fileList){
      let val = '';
      fileList.forEach(item => { if(item.response){val = val + item.response.data.id + ','} })
      this.fileList = fileList;
      this.ruleForm.fujian = val.substring(0, val.length - 1);
    },
    addcaigouSignUp(){
      let that = this;
      that.$refs['form'].validate((valid) => {
        if (valid) {
          that.ruleForm.xxid = this.$route.query.id
          that.ruleForm.api_auth_uid = JSON.parse(window.localStorage.getItem("individual")).id
          request.supplierPurchaseSignUp(that.ruleForm).then((res) => {
            if(res.data.code == 1){
              that.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.SignUpType = false;
            }else{
              that.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    changeNodes (data) {
      if (data.length > 0) {
        for (var i = 0; i < data.length;i++) {
          data[i].val = data[i].lable == ''?undefined:data[i].lable;
          data[i].type = data[i].value == ''?undefined:data[i].value;
          if(data[i].children){
            for (var a = 0; a < data[i].children.length;a++) {
              data[i].children[a].val = data[i].children[a].lable == undefined?'':data[i].children[a].lable
              data[i].children[a].type = data[i].children[a].value == undefined?'':data[i].children[a].value
              if(data[i].children[a].children){
                for (var z = 0; z < data[i].children[a].children.length;z++) {
                  data[i].children[a].children[z].val = data[i].children[a].children[z].lable == undefined?'':data[i].children[a].children[z].lable
                  data[i].children[a].children[z].type = data[i].children[a].children[z].value == undefined?'':data[i].children[a].children[z].value
                }
              }
            }
          }
        }
      }
      return data;
    },
  }
}
</script>

<style lang="scss" scoped>
  .publishMessage_border{
    display: block;
    width: 100%;
    margin:0 auto 16px;
    border-bottom: 1px dashed #E9E9E9;
  }
  .purchase_detail{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    font-size: 0;
    min-height: 70vh;
    .Purchase_list_left{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 20px - 340px);
      margin-right: 20px;
      background-color: #fff;
      .purchase_detail_in{
        padding: 20px;
        background-color: #fff;
        .Purchase_list_left_top{
          padding: 0 18px;
          height: 60px;
          line-height: 60px;
          background: #F0F0F0;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #313131;
          span{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0089EF;
            margin-right: 20px;
          }
          div{
            float: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #929292;
            cursor: pointer;
            img{
              vertical-align: middle;
              height: 14px;
              margin-right: 4px;
            }
          }
        }
        .purchase_detail_Message{
          background: #FFFFFF;
          border: 1px solid #E8E8E8;
          padding: 20px;
          margin: 20px 0;
          .purchase_detail_phone{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #E2E2E2;
            padding-bottom: 16px;
            .purchase_detail_phone_1{
              display: block;
              flex: 1;
              img{
                display: block;
                width: 40px;
                height: 40px;
                margin-bottom: 4px;
              }
              p{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #828282;
                line-height: 20px;
              }
            }
            .purchase_detail_phone_2{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 20px;
              flex: 1;
              i{
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                background: #EEEEEE;
                border-radius: 50%;
                margin-right: 7px;
              }
            }
            .purchase_detail_phone_3{
              button{
                width: 90px;
                height: 30px;
                border: 1px solid #0089EF;
                outline: none;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #0089EF;
                background-color: #fff;
                display: block;
                margin-bottom: 4px;
                margin-top: 15px;
              }
              span{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #828282;
              }
            }
          }
          .purchase_detail_item{
            padding: 10px 0;
            .item_list_text{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 20px;
              i{
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                background: #EEEEEE;
                border-radius: 50%;
                margin-right: 7px;
              }
            }
            .purchase_detail_val{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #B7B7B7;
              line-height: 20px;
              padding:0 13px;
              margin-top: 6px;
            }
          }
        }
        .purchase_detail_SignUp{
          border: 1px solid #E8E8E8;
        }
        .purchase_detail_SignUp_from{
          margin: 0 20px;
          .purchase_detail_SignUp_from_but{
            display: block;
            margin:24px auto;
            width: 119px;
            height: 36px;
            background: #0089EF;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0px;
          }
        }
      }
    }
    .Purchase_list_right{
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      .Purchase_list_right_in{
        width: 300px;
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        .Purchase_list_right_top{
          background-color: #F0F0F0;
          line-height: 60px;
          padding-left: 27px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #595959;
        }
        .ProjectRecommendation{
          background: #FFFFFF;
          border: 1px solid #0089EF;
          padding: 20px;
          margin-top: 20px;
          cursor: pointer;
          .ProjectRecommendation_tit{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0089EF;
            line-height: 25px;
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #EF0000;
              line-height: 20px;
              vertical-align: middle;
              margin-left: 25px;
            }
          }
          p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #828282;
            line-height: 20px;
            margin: 4px 0 ;
          }
          .ProjectRecommendation_text{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #828282;
            line-height: 20px;
            i{
              display: inline-block;
              vertical-align: middle;
              width: 6px;
              height: 6px;
              background: #EEEEEE;
              border-radius: 50%;
              margin-right: 7px;
            }
          }
          span{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C3C3C3;
            line-height: 17px;
            margin-top: 8px;
          }
        }
      }
    }
  }
</style>
